<template lang="pug">
it-modal(v-model="modelValue")
  template(#header)
    .price-list-form-modal__header Прайслист

  template(#body)
    form.price-list-form(id="price-list" @submit.prevent="submit")
      label.price-list-form__label
        span.price-list-form__label-title Тип
        it-input(v-model="priceList.type" required)
      label.price-list-form__label
        span.price-list-form__label-title Название
        it-input(v-model="priceList.name")   
      
  template(#actions)
    it-button(type="danger" outlined @click="$emit('update:modelValue', false)") Отменить
    it-button(type="primary" form="price-list" :loading="loading") Создать

</template>

<style lang="sass" src="./index.sass"></style>


<script>
import emptyPriceList from '@/assets/emptyPriceList'

export default {
  name: 'PriceListFormModal',
  props: {
    modelValue: Boolean,
    priceListId: [String, Number]
  },
  emits: ['update:modelValue', 'priceList:create'],
  data() {
    return {

      loading: false,
      priceList: {...emptyPriceList}

    }
  },
  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue)
      if ( !this.modelValue ) this.priceList = {...emptyPriceList}
    }
  },
  

  methods: {
    async submit() {
      if ( this.loading ) return
      this.loading = true

      try {
        const url = this.priceListId ? `/pricelists/${this.priceListId}/copy` : `/pricelists`
        const {data} = await this.$api.post(url, { priceList: this.priceList })
        this.loading = false
        this.$emit('priceList:create', data.result.priceList)
      } catch (err) {
        console.log(err)
        this.$notification.error( err?.response?.data?.message || err.message )
      }

      this.loading = false
    }
  }
}
</script>
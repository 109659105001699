<template lang="pug">
.query-search
  .query-search-input
    it-input(v-model="query" @keydown.enter="search")
    it-icon.query-search-input__clear(v-show="query" name="close" @click="clear")
  it-button(type="primary" @click="search") Поиск
</template>

<style lang="sass" src="./index.sass"></style>


<script>
export default {
  name: 'QuerySearch',
  emits: ['search'],
  data() {
    return { query: null }
  },

  watch: {
    queryReq: {
      handler() {
        if ( this.query !== this.queryReq ) this.query = this.queryReq
      },
      immediate: true
    }
  },
  methods: {
    search() {
      const query = { ...this.$route.query }

      if ( !this.query || this.query === '' ) {
        delete query.query
      } else query.query = this.query.toLowerCase()


      this.$router.push({
        path: this.$route.path,
        query: query
      })
      this.$emit('search')
    },

    clear() {
      this.query = ''
      if ( this.queryReq && this.queryReq !== '' ) this.search()
    }
  }
}
</script>
<template lang="pug">
.page.page-with-table.pricelists-page
  .pricelists-page-header
    .pricelists-page-header__title Прайслисты
    query-search.pricelists-page-header__search
    it-button(v-if="isAdmin" type="primary" @click="priceListFormModal = true") Создать прайслист
  

  data-table.page__table.pricelists-page__table(
    :loading="loading" :headers="headers" :items="filteredPriceLists" modelName="priceList"
    :editApiMethod="editApiMethod" :editApiUrl="editApiUrl" @update:item="updatePriceList"
    :deleteApiMethod="deleteApiMethod" :deleteApiUrl="deleteApiUrl" @delete:item="deletePriceList")

    template(v-slot:setDefault="{item}")
      it-button(
        v-if="!item.isDefault && isAdmin" type="black" outlined
        :loading="setDefaultLoading && setDefaultItemId === item.id" @click="setDefault(item.id)" ) Установить
      it-switch(v-else v-model="item.isDefault" disabled)

    template(v-slot:products="{item}")
      router-link(:to="`/pricelists/${item.id}`"): it-button(type="primary" outlined) Смотреть

    template(v-slot:copy="{item}")
      it-button( type="warning" outlined  @click="copy(item.id)") Копировать


  price-list-form-modal(v-if="isAdmin" v-model="priceListFormModal" :priceListId="copyPriceListId" @priceList:create="create")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import QuerySearch from '@/components/QuerySearch'
import DataTable from '@/components/DataTable'
import PriceListFormModal from '@/components/PriceListFormModal'


export default {
  name: 'PriceListsPage',
  components: { QuerySearch, DataTable, PriceListFormModal },
  data() {
    return {

      loading: false,
      priceLists: [],

      priceListFormModal: false,
      copyPriceListId: null,

      editApiMethod: 'patch',
      editApiUrl: '/pricelists',
      deleteApiMethod: 'delete',
      deleteApiUrl: '/pricelists',

      setDefaultLoading: false,
      setDefaultItemId: null,
    }
  },
  computed: {
    headers() {
      const headers =  [
        {title: 'id', value: 'id', readonly: true, sortable: true},
        {title: 'Тип', value: 'type', sortable: true},
        {title: 'Название', value: 'name', sortable: false},
        {title: 'Дата', value: 'date', inputType: 'date', sortable: false},
        {title: 'Активный', value: 'isActive', sortable: false, boolean: true},
        {title: 'Основной', value: 'setDefault'},
        {title: 'Продукты', value: 'products', readonly: true, sortable: false},
      ]
      if ( this.isAdmin ) headers.push({ title: 'Копирование', value: 'copy' })

      return headers
    },

    filteredPriceLists() {
      if ( !this.queryReq || this.queryReq === '' ) return this.priceLists
      return this.filterByQuery(this.priceLists)
    }
  },
  watch: {
    priceListFormModal() {
      if ( !this.priceListFormModal ) this.copyPriceListId = null
    }
  },


  methods: {
    async getPriceLists() {
      this.loading = true

      try {
        const {data} = await this.$api.get('/pricelists')
        this.priceLists = data.result.data
      } catch(err) {
        console.log(err)
        this.$notification.error(err?.response?.data?.message || err.message)
      }

      this.loading = false
    },

    async setDefault(id) {
      if ( this.setDefaultLoading ) return
      this.setDefaultItemId = id
      this.setDefaultLoading = true

      try {
        await this.$api.post(`/pricelists/default/set/${id}`)
        
        const prevActiveIndex = this.priceLists.findIndex( elem => elem.isDefault === true )
        const newActiveIndex = this.priceLists.findIndex( elem => elem.id === id )
        if ( prevActiveIndex >= 0 ) this.priceLists[prevActiveIndex].isDefault = false
        this.priceLists[newActiveIndex].isDefault = true

      } catch (err) {
        console.log(err)
        this.$notification.error(err?.response?.data?.message || err.message)
      }

      this.setDefaultLoading = false
    },

    create({ id }) {
      this.priceListFormModal = false
      this.$notification.success('Прайслист создан')
      this.$router.push(`/pricelists/${id}`)
    },
    copy(id) {
      this.copyPriceListId = id
      this.priceListFormModal = true
    },


    updatePriceList(item) {
      const index = this.priceLists.findIndex( elem => elem.id === item.id )
      this.priceLists[index] = {...item}
    },
    deletePriceList(id) {
      const index = this.priceLists.findIndex( elem => elem.id === id )
      this.priceLists.splice(index, 1) 
    }
  },

  mounted() {
    this.getPriceLists()
  }
}
</script>